<template>
   <div class="forbidden">
      <div>
         <h3>You are not authorized to access this site</h3>
      </div>
   </div>
</template>

<script>
export default {
  name: 'forbidden',
}
</script>

<style scoped>
div.forbidden {
  padding: 15px 25px;
  min-height: 600px;
  background: white;
}
</style>