import { render, staticRenderFns } from "./InstitutionSearch.vue?vue&type=template&id=7d64e509&scoped=true&"
import script from "./InstitutionSearch.vue?vue&type=script&lang=js&"
export * from "./InstitutionSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d64e509",
  null
  
)

export default component.exports