<template>
   <div class="content press">
      <h2>Press</h2>
      <p><b>TEXT:</b></p>
      <p>
         <a href="https://blog.bookstellyouwhy.com/redefining-the-rare-book-an-interview-on-andrew-stauffers-book-traces"
            target="_blank">"Redefining the Rare Book"</a>
         <br/>Audrey Golden
          <br>
         <strong>Books Tell You Why</strong>
         <br/>February 26, 2021
      </p>
      <p>
         <a href="https://content.fromthepage.com/kristin-jensen/"
            target="_blank">"An Interview with Kristin Jensen of BookTraces.org"</a>
         <br>Nick Allen
         <br>
         <strong>FromThePage</strong>
         <br>October 7, 2020
      </p>
      <p>
         <a href="http://www.mountholyokenews.com/news/2020/2/14/kristin-jensen-99-presents-book-traces-project" target="_blank">
         Kristin Jensen '99 Presents Book Traces Project
         </a>
         <br/>Katie Goss
         <br/>Mt. Holyoke News
         <br/>February 14, 2019<br/>
      </p>
      <p>
         <a href="https://www.news.virginia.edu/content/uva-library-opens-window-lost-love"
            target="_blank">
            "Love in the Margins"
         </a>
         <br>Katie McNally
         <br>
         <strong>UVA Today</strong>
         <br>February 14, 2017
      </p>
      <p>
         <a href="https://blog.bookstellyouwhy.com/book-traces-interview-with-professor-andrew-stauffer"
            target="_blank">
            "Book Traces: An interview with Professor Andrew Stauffer"
         </a>
         <br>Audrey Golden
         <br>
         <strong>Books Tell You Why</strong>
         <br>April 3, 2016
      </p>
      <p>
         <a href="http://theconversation.com/book-traces-crowdsourcing-the-history-of-19th-century-books-and-reading-26814"
            target="_blank">Book Traces: crowdsourcing the history of 19th-century books and reading
         </a>
         <br>
         <strong>The Conversation</strong>
         <br>May 15, 2014
      </p>
      <p>
         <a href="http://uvamagazine.org/articles/book_traces"
            target="_blank">
            "Book Traces: New Project Brings Hidden Messages in Books to Light"
         </a>
         <br>Anna Katherine Clemmons
         <br>
         <strong>UVA Alumni Magazine</strong>
         <br>December 2014
      </p>
      <p>
         <a href="http://www.nytimes.com/2014/12/19/arts/design/of-magic-lanterns-and-screen-gems.html"
            target="_blank">
            "Found in the Margins"
         </a> (click and scroll down)
         <br>Eve Kahn
         <br>
         <strong>New York Times</strong>
         <br>December 19, 2014
      </p>
      <p>
         <a href="http://www.theatlantic.com/technology/archive/2014/05/what-is-a-book/361876/"
            target="_blank">
            What is a Book? Not just a bag of words, but a thing held by human hands
         </a>
         <br>Alexis C. Madrigal
         <br>
         <strong>The Atlantic</strong>
         <br>May 7, 2014
      </p>
      <p>
         <a href="http://chronicle.com/blogs/wiredcampus/book-lovers-record-traces-of-19th-century-readers/52415"
            target="_blank">Book Lovers Record Traces of 19th-Century Readers</a>
         <br>Jennifer Howard
         <br>
         <strong>The Chronicle of Higher Education</strong>
         <br>May 5, 2014
      </p>
      <p>
         <a href="http://hyperallergic.com/125215/the-call-to-action-to-save-digitized-books-from-oblivion/"
            target="_blank">The Call to Action to Save Digitized Books from Oblivion</a>
         <br>Allison Meier
         <br>
         <strong>Hyperallergic.com</strong>
         <br>May 8, 2014
      </p>
      <p>
         <a href="https://blogs.cul.columbia.edu/rbml/2014/08/29/book-traces-comes-to-the-butler-stacks-on-october-8th/"
            target="_blank">Book Traces comes to Butler Stacks on October 8</a>
         <br>Karla Nielsen
         <br>Columbia University Rare Book and Manuscript Library blog
         <br>August 29, 2014
      </p>
      <p>
         <strong>"Traces of Previous Readers"</strong>
         <br>Mitch Fraas
         <br>
         <strong>
            <em>Fine Books and Collections</em>
         </strong> 12.4
         <br>Autumn 2014 issue
      </p>
      <p>
         <a href="http://news.virginia.edu/content/hidden-margins-uva-project-saves-unique-pages-past"
            target="_blank">"Hidden in the Margins: U.Va. Project Saves Unique Pages of the Past"</a>
         <br>Anne E. Bromley
         <br>
         <strong>UVA Today</strong>
         <br>October 31, 2014
      </p>
      <p>
         <a href="https://news.virginia.edu/content/grant-boosts-efforts-catalog-secrets-hidden-old-library-books"
            target="_blank">"Grant Boosts Efforts to Catalog Secrets Hidden in Old Library Books"</a>
         <br>Anne E. Bromley
         <br>
         <strong>UVA Today</strong>
         <br>December 16, 2014
      </p>
      <p>
         <strong>"Book Traces: Preserving Bibliodiversity"</strong>
         <br>Grant Hurley
         <br>
         <strong>
            <i>Amphora</i>
         </strong> 168
         <br>2014
      </p>

      <p><b>AUDIO:</b></p>
      <p>
         Interview with
         <a href="http://ow.ly/SKOgU" target="_blank">
            Alison Quantz for
            <strong>With Good Reason, on NPR</strong>
         </a>
         <br>September 26, 2015
      </p>
      <p>
         <a href="http://www.cbc.ca/spark/blog/2014/09/21/book-traces/" target="_blank">
            Interview with Nora Young, for
            <strong>Spark, on CBC Radio</strong>
         </a>
         <br>September 21, 2014
      </p>
      <p>
         <a href="https://soundcloud.com/wtju/magic-in-the-margins-book-traces-with-andrew-stauffer"
            target="_blank">
            Interview with Elizabeth Richardson-Lorente, on
            <strong>WTJU Soundboard</strong>
         </a>
         <br>November 13, 2014
      </p>
   </div>
</template>

<script>
export default {
   name: "press",
   methods: {}
};
</script>

<style scoped>
.lead {
   margin-bottom: 30px;
}
</style>