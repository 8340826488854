<template>
   <div class="content thanks">
      <h2>Submission Successful</h2>
      <h4>Thank you for your submission to Book Traces!</h4>
      <p>It will appear on the site shortly. When it does, please share it widely on Facebook and Twitter.</p>
      <p>If you want to edit or add information to the post, just contact <a href="mailto:ams4k@virginia.edu">Andrew Stauffer (ams4k@virginia.edu)</a> 
         or <a href="mailto:khj5c@virginia.edu">Kristin Jensen (khj5c@virginia.edu)</a>.</p>
      <p>Thanks again for becoming a seeker for the Book Traces team!</p>
   </div>
</template>

<script>
export default {
   name: "thanks",
   methods: {}
};
</script>

<style scoped>
</style>